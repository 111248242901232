.ra-calendar-label {
    font-family: "Open Sans", "Arial", "Helvetica", sans-serif;
    background: #fafafa;
    padding: 0 8px !important;
}

.react-daterange-picker {
    display: inline-flex;
    position: relative;
}

.react-daterange-picker,
.react-daterange-picker *,
.react-daterange-picker *:before,
.react-daterange-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-daterange-picker--disabled {
    background-color: rgb(240, 240, 240);
    color: rgb(109, 109, 109);
}

.react-daterange-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    min-height: 41px;
}

.react-daterange-picker__inputGroup {
    min-width: calc(4px + (4px * 3) + 0.54em * 8 + 0.217em * 2);
    flex-grow: 1;
    display: flex;
    padding: 2px;
    align-items: baseline;
}

.react-daterange-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
}

.react-daterange-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
}

.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.react-daterange-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
}

.react-daterange-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px +  0.54em);
}

.react-daterange-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
}

.react-daterange-picker__button:enabled {
    cursor: pointer;
}

.react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon {
    stroke: rgb(0, 120, 215);
}

.react-daterange-picker__button:disabled .react-daterange-picker__button__icon {
    stroke: rgb(109, 109, 109);
}

.react-daterange-picker__button svg {
    display: inherit;
}

.react-daterange-picker__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999;
}

.react-daterange-picker__calendar .react-calendar {
    border-width: thin;
}
